import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>Nuestra web está en manteimiento, puedes encontrarnos en <a className="App-link"
          href="https://instagram.com/estudiodonate"
          target="_blank"
          rel="noopener noreferrer">@EstudioDonate</a></p>
      </header>
    </div>
  );
}

export default App;
